<template>
  <div class="about">
    <!-- <img src="../../assets/imgs/aboutus.png" alt=""> -->
    <div class="title">公司简介</div>
    <div class="subtitle">About Us</div>
    <div class="content">
      《深燃》成立于2020年8月，属于北京艾莉亚信息科技有限公司，是一个聚焦创新经济、专注优质内容的新媒体平台，由资深媒体人联合创立，恪守新闻规范与职业道德，坚持独立客观的报道立场，以财经视角及时跟踪产业创新、深刻解读产业变革。<br />
      <br />
      《深燃》旗下内容品牌包括深燃、开菠萝财经、深燃视频、梦姐和菲哥等。
    </div>
  </div>
</template>
<script>

export default {
  mounted () {
    window.scrollTo(0, 0)
  }
}

</script>
<style scoped>
.about {
  overflow: hidden;
  padding: 0 135px 137px;
  text-align: center;
  background: #000 url(../../assets/imgs/aboutus.png) no-repeat;
  /* border: 1px solid; */
}
.title {
  margin-top: 400px;
  height: 64px;
  font-size: 56px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 64px;
}
.subtitle {
  margin-top: 18px;
  height: 31px;
  font-size: 24px;
  font-family: Futura;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.5);
  line-height: 31px;
}
.content {
  margin-top: 90px;
  height: 336px;
  text-align: left;
  font-size: 28px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}
</style>
